<template>
    <div>
        <ts-page-title
            :title="$t('incomeStatementDetail.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('incomeStatementDetail.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <ts-loading-banner :loading="loading">
                    <div class="tw-flex tw-space-x-3">
                        <a-range-picker
                            v-model="dateRange"
                            :allowClear="false"
                            format="DD-MM-YYYY"
                            valueFormat="DD-MM-YYYY"
                            style="width: 200px"
                            @change="fetchData()"
                        />
                        <ts-button color="primary" @click.prevent="fetchData">{{
                            $t("view")
                        }}</ts-button>
                        <ts-button outline @click.prevent="pdf" color="info">{{
                            $t("pdf")
                        }}</ts-button>
                    </div>
                    <div class="tw-mt-4 tw-p-4">
                        <table class="tw-w-full">
                            <tr class="tw-border-b-2 tw-border-blue-500">
                                <td>
                                    <h3>Revenue</h3>
                                </td>
                                <td class="tw-text-right tw-w-56"></td>
                            </tr>
                            <tr
                                v-for="(r, index) in resources[0]"
                                :key="index + '_'"
                            >
                                <td class="tw-pl-8 tw-py-1 tw-font-semibold">
                                    {{ r.item_name }}
                                </td>
                                <td
                                    class="tw-w-3 tw-border tw-text-right tw-font-semibold tw-px-3"
                                >
                                    <ts-accounting-format
                                        :digit="2"
                                        :value="
                                            r.amount ? parseFloat(r.amount) : 0
                                        "
                                    ></ts-accounting-format>
                                </td>
                            </tr>
                            <tr>
                                <td class="tw-bg-gray-100 tw-py-1 tw-px-3">
                                    <h4 class="tw-mb-0 tw-text-gray-400">
                                        Total Revenue
                                    </h4>
                                </td>
                                <td class="tw-bg-gray-100 tw-px-3">
                                    <ts-accounting-format
                                        :digit="2"
                                        :value="sumAmount(resources[0])"
                                    ></ts-accounting-format>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" height="20"></td>
                            </tr>
                            <tr class="tw-border-b-2 tw-border-blue-500">
                                <td colspan="2">
                                    <h3>Expense</h3>
                                </td>
                            </tr>
                            <tr v-for="(p, index) in resources[1]" :key="index">
                                <td class="tw-pl-8 tw-py-1 tw-font-semibold">
                                    {{ p.item_name }}
                                </td>
                                <td
                                    class="tw-w-3 tw-border tw-text-right tw-font-semibold tw-px-3"
                                >
                                    <ts-accounting-format
                                        :digit="2"
                                        :value="
                                            p.amount ? parseFloat(p.amount) : 0
                                        "
                                    ></ts-accounting-format>
                                </td>
                            </tr>
                            <tr>
                                <td class="tw-bg-gray-100 tw-py-1 tw-px-3">
                                    <h4 class="tw-mb-0 tw-text-gray-400">
                                        Total Expense
                                    </h4>
                                </td>
                                <td class="tw-bg-gray-100 tw-px-3">
                                    <ts-accounting-format
                                        :digit="2"
                                        :value="sumAmount(resources[1])"
                                    ></ts-accounting-format>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" height="20"></td>
                            </tr>
                            <tr>
                                <td height="20"></td>
                            </tr>
                            <tr class="tw-border-b-2 tw-border-blue-500">
                                <td>
                                    <h3>Net Income</h3>
                                </td>
                                <td class="tw-text-right tw-px-3">
                                    <ts-accounting-format
																				class="tw-text-2xl"
                                        :digit="2"
                                        :value="netIncome"
                                    ></ts-accounting-format>
                                </td>
                            </tr>
                        </table>
                    </div>
                </ts-loading-banner>
            </ts-panel-wrapper>
        </ts-panel>
        <a-modal
            :title="$t('pdf')"
            width="50%"
            v-model="preview_pdf"
            :footer="null"
        >
            <ts-preview-pdf v-model="waiting" :src="src" />
        </a-modal>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import { sumBy } from "lodash";
export default {
    name: "incomeStatementDetail",
    data() {
        return {
            loading: false,
            preview_pdf: false,
            waiting: false,
            src: "",
            year: undefined
        };
    },
    created() {
        this.year = moment(this.dateRange[0], "DD-MM-YYYY").format("YYYY");
    },
    computed: {
        ...mapState("report/incomeStatementDetail", ["resources"]),
        dateRange: {
            get() {
                return this.$store.state.report.incomeStatementDetail.dateRange;
            },
            set(newValue) {
                this.$store.commit(
                    "report/incomeStatementDetail/SET_DATE_RANGE",
                    newValue
                );
            }
        },
        netIncome() {
            var revenue = sumBy(this.resources[0], o => {
                return o.amount ? parseFloat(o.amount) : 0;
            });
            var expense = sumBy(this.resources[1], o => {
                return o.amount ? parseFloat(o.amount) : 0;
            });

            return revenue - expense;
        },
        sumAmount: () => resource => {
            let amount = sumBy(resource, o => {
                return parseFloat(o.amount);
            });

            return amount;
        }
    },
    methods: {
        ...mapActions("report/incomeStatementDetail", ["getFormViewData"]),
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("report/incomeStatementDetail/fetch", {
                    ...attributes
                })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        pdf() {
            this.preview_pdf = true;
            this.waiting = true;
            this.$store
                .dispatch("report/incomeStatementDetail/toPdf")
                .then(response => {
                    this.src = response.url;
                })
                .catch(() => {
                    this.preview_pdf = false;
                })
                .finally(() => {
                    this.waiting = false;
                });
        }
    },
    watch: {
        dateRange: function(value) {
            this.year = moment(value[0], "DD-MM-YYYY").format("YYYY");
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    }
};
</script>
